exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-1-js": () => import("./../../../src/pages/about-us-1.js" /* webpackChunkName: "component---src-pages-about-us-1-js" */),
  "component---src-pages-about-us-2-js": () => import("./../../../src/pages/about-us-2.js" /* webpackChunkName: "component---src-pages-about-us-2-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/blog-1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-4-js": () => import("./../../../src/pages/blog-4.js" /* webpackChunkName: "component---src-pages-blog-4-js" */),
  "component---src-pages-blog-5-js": () => import("./../../../src/pages/blog-5.js" /* webpackChunkName: "component---src-pages-blog-5-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-2-js": () => import("./../../../src/pages/faq-2.js" /* webpackChunkName: "component---src-pages-faq-2-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-10-js": () => import("./../../../src/pages/index-10.js" /* webpackChunkName: "component---src-pages-index-10-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-4-js": () => import("./../../../src/pages/index-4.js" /* webpackChunkName: "component---src-pages-index-4-js" */),
  "component---src-pages-index-5-js": () => import("./../../../src/pages/index-5.js" /* webpackChunkName: "component---src-pages-index-5-js" */),
  "component---src-pages-index-6-js": () => import("./../../../src/pages/index-6.js" /* webpackChunkName: "component---src-pages-index-6-js" */),
  "component---src-pages-index-7-js": () => import("./../../../src/pages/index-7.js" /* webpackChunkName: "component---src-pages-index-7-js" */),
  "component---src-pages-index-8-js": () => import("./../../../src/pages/index-8.js" /* webpackChunkName: "component---src-pages-index-8-js" */),
  "component---src-pages-index-9-js": () => import("./../../../src/pages/index-9.js" /* webpackChunkName: "component---src-pages-index-9-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-1-js": () => import("./../../../src/pages/portfolio-1.js" /* webpackChunkName: "component---src-pages-portfolio-1-js" */),
  "component---src-pages-portfolio-2-js": () => import("./../../../src/pages/portfolio-2.js" /* webpackChunkName: "component---src-pages-portfolio-2-js" */),
  "component---src-pages-portfolio-3-js": () => import("./../../../src/pages/portfolio-3.js" /* webpackChunkName: "component---src-pages-portfolio-3-js" */),
  "component---src-pages-portfolio-4-js": () => import("./../../../src/pages/portfolio-4.js" /* webpackChunkName: "component---src-pages-portfolio-4-js" */),
  "component---src-pages-portfolio-5-js": () => import("./../../../src/pages/portfolio-5.js" /* webpackChunkName: "component---src-pages-portfolio-5-js" */),
  "component---src-pages-portfolio-6-js": () => import("./../../../src/pages/portfolio-6.js" /* webpackChunkName: "component---src-pages-portfolio-6-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-products-list-js": () => import("./../../../src/pages/products-list.js" /* webpackChunkName: "component---src-pages-products-list-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-single-blog-1-js": () => import("./../../../src/pages/single-blog-1.js" /* webpackChunkName: "component---src-pages-single-blog-1-js" */),
  "component---src-pages-single-blog-2-js": () => import("./../../../src/pages/single-blog-2.js" /* webpackChunkName: "component---src-pages-single-blog-2-js" */),
  "component---src-pages-single-blog-3-js": () => import("./../../../src/pages/single-blog-3.js" /* webpackChunkName: "component---src-pages-single-blog-3-js" */),
  "component---src-pages-single-portfolio-js": () => import("./../../../src/pages/single-portfolio.js" /* webpackChunkName: "component---src-pages-single-portfolio-js" */),
  "component---src-pages-single-services-js": () => import("./../../../src/pages/single-services.js" /* webpackChunkName: "component---src-pages-single-services-js" */),
  "component---src-pages-team-1-js": () => import("./../../../src/pages/team-1.js" /* webpackChunkName: "component---src-pages-team-1-js" */),
  "component---src-pages-team-2-js": () => import("./../../../src/pages/team-2.js" /* webpackChunkName: "component---src-pages-team-2-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

